<template>
  <v-main>
    <v-container>
      <h1 class="display-1">Features</h1>
      <div class="text-center">
        <about-features
          :outlined="$route.name != 'Features'"
          :text="$route.name != 'Features'"
        ></about-features>
      </div>
      <div>
        <router-view></router-view>
      </div>
    </v-container>
  </v-main>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: 'Features'
    }
  }
}
</script>

<style></style>
